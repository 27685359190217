
import { Col, message, Input, Layout, Row, Progress, Spin, Tag, Empty } from "antd";

import React, { useState, useRef, useEffect } from "react";

import {UnorderedListOutlined, AppstoreOutlined} from '@ant-design/icons'
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BaseApi } from "../../config/api/BaseApi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { notiError, notiSuccess } from "../../utils/notication";
import { Loader } from "react-feather";

import queryString from 'query-string';

const Homepage = () => {

    



    

  
  return (
    <>
        <div className="app-page">
            <div className="bg-darkblue">
                <HeaderApp />
                <div className="container">
                    <div className="logo-game">
                        <img src="../images/hiro-logo.svg" />
                    </div>
                    <div className="game-description">
                        Levelling up the fun
                    </div>
                    <div className="sub-desc">AVAILABLE 2025</div>
                    <div className="mt-15 game-info">
                        <Row gutter={30}>
                            <Col xs={24} sm={6}>
                                <div className="item-info">
                                    <a className="text-white" href="https://helalabs.gitbook.io/gamehiro-docs/" target="_blank">
                                        <div className="mb-10 bg-black">
                                            <img width="" src="../images/docs.svg" />
                                        </div>
                                        <div className="flex-center">
                                            <span className="text-white fsize-18">Documents</span>
                                            <img width="20" src="../images/ico.svg" />
                                        </div>
                                    </a>
                                </div>
                            </Col>
                            <Col xs={24} sm={6}>
                                <div className="item-info">
                                    <a className="text-white" href="https://x.com/GameHiro_X?s=35" target="_blank">
                                        <div className="mb-10 bg-black">
                                            <img width="" src="../images/x-grey.svg" />
                                        </div>
                                        <div className="flex-center">
                                            <span className="text-white fsize-18">Follow us on X</span>
                                            <img width="20" src="../images/ico.svg" />
                                        </div>
                                    </a>
                                </div>
                            </Col>
                            <Col xs={24} sm={6}>
                                <div className="item-info">
                                    <a className="text-white" href="https://t.me/gamehiro_x" target="_blank">
                                        <div className="mb-10 bg-black">
                                            <img width="" src="../images/te.svg" />
                                        </div>
                                        <div className="flex-center">
                                            <span className="text-white fsize-18">Telegram</span>
                                            <img width="20" src="../images/ico.svg" />
                                        </div>
                                    </a>
                                </div>
                            </Col>
                            <Col xs={24} sm={6}>
                                <div className="item-info">
                                    <a className="text-white" href="https://gamehiro.com/" target="_blank">
                                        <div className="mb-10 bg-black">
                                            <img width="" src="../images/website.svg" />
                                        </div>
                                        <div className="flex-center">
                                            <span className="text-white fsize-18">Website</span>
                                            <img width="20" src="../images/ico.svg" />
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="img-bt">
                    <img src="../images/bg-bot.png" />
                </div>
            </div>
            
            
        </div>
    </>
  );
};
export default Homepage;
